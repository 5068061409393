import React, { FC } from "react";
const AppSpinner: FC = () => {
  return (
    <div className="pt-3 text-center">
      <div className="spinner-border"></div>
    </div>
  );
};

export default React.memo(AppSpinner);
