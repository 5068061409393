import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  IAuthenticationCallback,
} from "amazon-cognito-identity-js";

let userPool: CognitoUserPool;
let currentUser: CognitoUser | null;
let checkUserIdpUrl: string = "";
let sessionUserAttributes: SessionUserAttributes;

interface SessionUserAttributes {
  email: string;
  currentUser: CognitoUser | null;
}

export async function fetchConfig(clientId: string, userPoolParam: string) {
  try {
    fetch("/login_env.json").then((res) => {
      res.json().then((itemEnv) => {
        if (userPoolParam) {
          userPool = new CognitoUserPool({
            UserPoolId: `${userPoolParam}`,
            ClientId: clientId,
          });
        } else {
          userPool = new CognitoUserPool({
            UserPoolId: `${itemEnv.userPoolId}`,
            ClientId: clientId,
          });
        }
        if (itemEnv && itemEnv.userPoolId && itemEnv.checkUserIdpUrl) {
          checkUserIdpUrl = itemEnv.checkUserIdpUrl;
          currentUser = userPool.getCurrentUser();
        } else {
          throw Error("Error on configuring");
        }
      });
    });
  } catch (error) {
    console.error("Error on getting config:", error);
  }
}

export function getCheckUserIdpUrl(): string {
  return checkUserIdpUrl;
}

export function getCurrentUser() {
  return currentUser;
}

function getCognitoUser(username: string) {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  return cognitoUser;
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser();
  }

  return new Promise<CognitoUserSession>(function (resolve, reject) {
    currentUser?.getSession(function (err: any, session: CognitoUserSession) {
      if (err) {
        reject(err);
      } else {
        resolve(session);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function verifyCode(username: string, code: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signInWithEmail(
  username: string,
  password: string,
): Promise<Object> {
  return new Promise<Object>(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    currentUser = getCognitoUser(username);
    const callbacks: IAuthenticationCallback = {
      onSuccess: function (res: any) {
        resolve(res);
      },
      onFailure: function (err: any) {
        reject(err);
      },

      // mfaRequired: function(codeDeliveryDetails) {
      //   // MFA is required to complete user authentication.
      //   // Get the code from user and call
      //   if(mfaEnabled){
      //     currentUser.sendMFACode(mfaCode, this);
      //   }
      // },

      newPasswordRequired: function (userAttributes, requiredAttributes) {
        delete userAttributes.email_verified;

        sessionUserAttributes = {
          email: userAttributes.email,
          currentUser: currentUser,
        };
        resolve({
          challengeName: "newPasswordRequired",
          userAttributes: sessionUserAttributes,
        });
      },
    };
    currentUser.authenticateUser(authenticationDetails, callbacks);
  }).catch((err) => {
    throw err;
  });
}

export async function completeNewPasswordChallenge(newPassword: string) {
  return new Promise(function (resolve, reject) {
    if (!sessionUserAttributes.currentUser) {
      reject(`could not find ${sessionUserAttributes.email}`);
      return;
    }
    sessionUserAttributes.currentUser.completeNewPasswordChallenge(
      newPassword,
      null,
      {
        onSuccess: function (res: any) {
          resolve(res);
        },
        onFailure: function (err: any) {
          reject(err);
        },
      },
    );
  }).catch((err) => {
    throw err;
  });
}

export function signOut() {
  if (currentUser) {
    currentUser.signOut();
  }
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    currentUser?.getUserAttributes(function (err: any, attributes: any) {
      if (err) {
        reject(err);
      } else {
        resolve(attributes);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function setAttribute(attribute: any) {
  return new Promise(function (resolve, reject) {
    const attributeList = Array<CognitoUserAttribute>();
    const res = new CognitoUserAttribute(attribute);
    attributeList.push(res);

    currentUser?.updateAttributes(attributeList, (err: any, res: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function sendCode(username: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(res);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function forgotPassword(
  username: string,
  code: string,
  password: string,
) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve("password updated");
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise(function (resolve, reject) {
    currentUser?.changePassword(
      oldPassword,
      newPassword,
      function (err: any, res: any) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      },
    );
  });
}
