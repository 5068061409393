import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import * as cognito from "../libs/cognito";
import { useSearchParams } from "react-router-dom";
import {
  toFormattedDateTime,
  validateCookieSize,
} from "@saas-repo/shared-lib/src/components/helper/utils";

export enum AuthStatus {
  Loading,
  SignedIn,
  NewPasswordRequired,
  SignedOut,
  ErrorOnSignIn,
}

export interface IAuth {
  sessionInfo?: {
    username?: string;
    email?: string;
    sub?: string;
    accessToken?: string;
    refreshToken?: string;
  };
  attrInfo?: any;
  authStatus?: AuthStatus;
  signInWithEmail?: any;
  signUpWithEmail?: any;
  signOut?: any;
  verifyCode?: any;
  getSession?: any;
  sendCode?: any;
  forgotPassword?: any;
  changePassword?: any;
  completeNewPasswordChallenge?: any;
  getAttributes?: any;
  setAttribute?: any;
  errorMessage?: string;
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
};

type Props = {
  children?: React.ReactNode;
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [errorMessage, setErrorMessage] = useState("");
  const [sessionInfo, setSessionInfo] = useState<{
    accessToken?: string;
    refreshToken?: string;
    email?: string;
  }>({});

  const [attrInfo, setAttrInfo] = useState([]);
  const [searchParams] = useSearchParams();
  const [clientId, setClientId] = useState(
    searchParams.get("client_id")
      ? decodeURI(searchParams.get("client_id") ?? "")
      : sessionStorage.getItem("x-realm-auth-client-id") ?? "",
  );
  const [userPoolParam] = useState(
    searchParams.get("user_pool")
      ? decodeURI(searchParams.get("user_pool") ?? "")
      : "",
  );

  useEffect(() => {
    const handleStorage = () => {
      const ci = sessionStorage.getItem("x-realm-auth-client-id") ?? clientId;
      if (clientId !== ci) setClientId(ci);
    };
    window.addEventListener("x-realm-auth-storage", handleStorage);
    return () =>
      window.removeEventListener("x-realm-auth-storage", handleStorage);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getSessionInfo();
    // eslint-disable-next-line
  }, [clientId]);

  if (authStatus === AuthStatus.Loading) {
    return null;
  }

  function deleteXRealmAuthCookies() {
    Object.keys(Cookies.get()).forEach(function (cookieName) {
      if (cookieName.startsWith("x-realm-auth-")) {
        Cookies.remove(cookieName, {
          path: "/",
          domain: `${window.location.hostname.replace("login.", "")}`,
          secure: true,
          samesite: "Lax",
        });
      }
    });
  }

  // amplify-provided information that ContactSuite depends on
  function generateUserDataCookie(sub: any, email: any) {
    Cookies.set(
      `CognitoIdentityServiceProvider.${clientId}.${sub}.userData`,
      JSON.stringify({
        UserAttributes: [
          {
            Name: "sub",
            Value: sub,
          },
          {
            Name: "email",
            Value: email,
          },
        ],
        Username: sub,
      }),
      {
        domain: `${window.location.hostname.replace("login.", "")}`,
        secure: true,
        samesite: "None",
        partitioned: true,
      },
    );
  }

  function moveLocalStorageInCookies() {
    //Move all localStorage into Cookies... filtered by end name
    Object.keys(localStorage).forEach((key) => {
      // eslint-disable-next-line
      let regextPattern = `CognitoIdentityServiceProvider\.${clientId}\.*\.(idToken|refreshToken|accessToken|LastAuthUser)$`;
      if (key.match(regextPattern)) {
        const itemValue = localStorage.getItem(key);
        if (!validateCookieSize(itemValue)) {
          let sub = `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`;
          throw new Error(
            `Too many entitlements for one user detected, contact your SAML administrator or Realm Support. Reference: ${localStorage.getItem(sub)}.${toFormattedDateTime(new Date())}`,
          );
        } else {
          Cookies.set(key, itemValue, {
            domain: `${window.location.hostname.replace("login.", "")}`,
            secure: true,
            samesite: "None",
            partitioned: true,
          });
        }
      }
    });
    Cookies.set("customLogin", "custom", {
      domain: `${window.location.hostname.replace("login.", "")}`,
    });
  }

  function signOut() {
    sessionStorage.removeItem("x-realm-auth-client-id");
    cognito.signOut();
    setAuthStatus(AuthStatus.SignedOut);
  }

  async function getSessionInfo() {
    try {
      if (clientId !== "") await cognito.fetchConfig(clientId, userPoolParam);
      await getSession().then(async (session) => {
        setSessionInfo({
          accessToken: session.getAccessToken().getJwtToken(),
          refreshToken: session.getRefreshToken().getToken(),
          email: session.getIdToken().payload.email,
        });

        moveLocalStorageInCookies();
        generateUserDataCookie(
          session.getIdToken().payload.sub,
          session.getIdToken().payload.email,
        );
        deleteXRealmAuthCookies();

        const attr: any = await getAttributes();
        setAttrInfo(attr);
        setAuthStatus(AuthStatus.SignedIn);
      });
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      let errorM = (err as Error).message;
      if (
        errorM !== "" &&
        errorM.indexOf("Too many entitlements for one user detected") > -1
      ) {
        setErrorMessage(errorM);
        setAuthStatus(AuthStatus.ErrorOnSignIn);
        throw new Error(errorM);
      }
    }
  }

  async function signInWithEmail(username: string, password: string) {
    try {
      const signInReponse = await cognito.signInWithEmail(username, password);
      if (
        Object.keys(signInReponse).includes("challengeName") &&
        signInReponse["challengeName"] === "newPasswordRequired"
      ) {
        setAuthStatus(AuthStatus.NewPasswordRequired);
        sessionStorage.removeItem("x-realm-auth-client-id");
        return AuthStatus.NewPasswordRequired;
      } else {
        try {
          await getSessionInfo();
          setAuthStatus(AuthStatus.SignedIn);
          sessionStorage.removeItem("x-realm-auth-client-id");
          return AuthStatus.SignedIn;
        } catch (err) {
          setAuthStatus(AuthStatus.ErrorOnSignIn);
          return AuthStatus.ErrorOnSignIn;
        }
      }
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  async function verifyCode(username: string, code: string) {
    try {
      await cognito.verifyCode(username, code);
    } catch (err) {
      throw err;
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession();
      return session;
    } catch (err) {
      throw err;
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes();
      return attr;
    } catch (err) {
      throw err;
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr);
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function sendCode(username: string) {
    try {
      await cognito.sendCode(username);
    } catch (err) {
      throw err;
    }
  }

  async function forgotPassword(
    username: string,
    code: string,
    password: string,
  ) {
    try {
      await cognito.forgotPassword(username, code, password);
    } catch (err) {
      throw err;
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword);
    } catch (err) {
      throw err;
    }
  }

  async function completeNewPasswordChallenge(newPassword: string) {
    try {
      await cognito.completeNewPasswordChallenge(newPassword);
      setAuthStatus(AuthStatus.SignedIn);
      await getSessionInfo();
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    completeNewPasswordChallenge,
    getAttributes,
    setAttribute,
    errorMessage,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
