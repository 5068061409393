import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./scss/index.scss";
import AppSpinner from "@saas-repo/shared-lib/src/components/AppSpinner";
import ApiProvider from "apiAxios/ApiProvider";
import AuthProvider from "./contexts/authContext";

export default function App() {
  // Containers
  const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

  return (
    <BrowserRouter>
      <Suspense fallback={<AppSpinner />}>
        <Routes>
          <Route
            path="*"
            element={
              <AuthProvider>
                <ApiProvider>
                  <DefaultLayout />
                </ApiProvider>
              </AuthProvider>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
