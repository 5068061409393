/* tslint:disable */
/* eslint-disable */
/**
 * Usan - Saas Login - API Gateway 3.0
 * This is an API Gateway to provide a list of lambda functions that Saas Login will use.
 *
 * OpenAPI spec version: 1.0.13
 * Contact: example@usan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosResponse,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
import { AdminGetUserRequest } from "../models";
/**
 * UserForgotPasswordApi - axios parameter creator
 * @export
 */
export const UserForgotPasswordApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Send reset code to users
     * @summary Reset user password
     * @param {AdminGetUserRequest} body
     * @param {string} version API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotUserPassword: async (
      body: AdminGetUserRequest,
      version: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling forgotUserPassword.",
        );
      }
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          "version",
          "Required parameter version was null or undefined when calling forgotUserPassword.",
        );
      }
      const localVarPath = `/v{version}/users/forgot-user-password`.replace(
        `{${"version"}}`,
        encodeURIComponent(String(version)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        (localVarRequestOptions.headers &&
          localVarRequestOptions.headers["Content-Type"] ===
            "application/json");
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * Send reset code to users
     * @summary Reset user password
     * @param {string} userName
     * @param {string} version API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotUserPasswordForm: async (
      userName: string,
      version: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userName' is not null or undefined
      if (userName === null || userName === undefined) {
        throw new RequiredError(
          "userName",
          "Required parameter userName was null or undefined when calling forgotUserPasswordForm.",
        );
      }
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          "version",
          "Required parameter version was null or undefined when calling forgotUserPasswordForm.",
        );
      }
      const localVarPath = `/v{version}/users/forgot-user-password`.replace(
        `{${"version"}}`,
        encodeURIComponent(String(version)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      if (userName !== undefined) {
        localVarFormParams.set("userName", userName as any);
      }
      localVarHeaderParameter["Content-Type"] =
        "application/x-www-form-urlencoded";
      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams.toString();

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserForgotPasswordApi - functional programming interface
 * @export
 */
export const UserForgotPasswordApiFp = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Send reset code to users
     * @summary Reset user password
     * @param {AdminGetUserRequest} body
     * @param {string} version API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forgotUserPassword(
      body: AdminGetUserRequest,
      version: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>
    > {
      const localVarAxiosArgs = await UserForgotPasswordApiAxiosParamCreator(
        configuration,
      ).forgotUserPassword(body, version, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Send reset code to users
     * @summary Reset user password
     * @param {string} userName
     * @param {string} version API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forgotUserPasswordForm(
      userName: string,
      version: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>
    > {
      const localVarAxiosArgs = await UserForgotPasswordApiAxiosParamCreator(
        configuration,
      ).forgotUserPasswordForm(userName, version, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UserForgotPasswordApi - factory interface
 * @export
 */
export const UserForgotPasswordApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     * Send reset code to users
     * @summary Reset user password
     * @param {AdminGetUserRequest} body
     * @param {string} version API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forgotUserPassword(
      body: AdminGetUserRequest,
      version: string,
      options?: AxiosRequestConfig,
    ): Promise<AxiosResponse<void>> {
      return UserForgotPasswordApiFp(configuration)
        .forgotUserPassword(body, version, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Send reset code to users
     * @summary Reset user password
     * @param {string} userName
     * @param {string} version API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forgotUserPasswordForm(
      userName: string,
      version: string,
      options?: AxiosRequestConfig,
    ): Promise<AxiosResponse<void>> {
      return UserForgotPasswordApiFp(configuration)
        .forgotUserPasswordForm(userName, version, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserForgotPasswordApi - object-oriented interface
 * @export
 * @class UserForgotPasswordApi
 * @extends {BaseAPI}
 */
export class UserForgotPasswordApi extends BaseAPI {
  /**
   * Send reset code to users
   * @summary Reset user password
   * @param {AdminGetUserRequest} body
   * @param {string} version API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserForgotPasswordApi
   */
  public async forgotUserPassword(
    body: AdminGetUserRequest,
    version: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<void>> {
    return UserForgotPasswordApiFp(this.configuration)
      .forgotUserPassword(body, version, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Send reset code to users
   * @summary Reset user password
   * @param {string} userName
   * @param {string} version API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserForgotPasswordApi
   */
  public async forgotUserPasswordForm(
    userName: string,
    version: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<void>> {
    return UserForgotPasswordApiFp(this.configuration)
      .forgotUserPasswordForm(userName, version, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
