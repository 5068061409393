import TimeAgo from "javascript-time-ago";
// English.
import en from "javascript-time-ago/locale/en";
TimeAgo.addDefaultLocale(en);
// Create formatter (English).
const timeAgo = new TimeAgo("en-US");

const awsConnectInstanceARNPattern = new RegExp(
  "^arn:aws:connect:(us(-gov)?|ap|ca|cn|eu|sa)-(central|(north|south)?(east|west)?)-(\\d):\\d{12}:instance/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}$",
);
const awsConnectContactRecordKinesisStreamARNPattern = new RegExp(
  "^arn:aws:kinesis:(us(-gov)?|ap|ca|cn|eu|sa)-(central|(north|south)?(east|west)?)-(\\d):\\d{12}:stream/[a-zA-Z0-9_.-]{1,128}$",
);
const s3BucketNamePattern = /^[a-z0-9][a-z0-9.-]{1,61}[a-z0-9]$/;
const kinesisStreamNamePattern = /^[a-zA-Z0-9_.-]{1,128}$/;
const awsAccountIdValidator = /^\d{12}$/;
const awsRegionValidator =
  /^(us(-gov)?|ap|ca|cn|eu|sa)-(central|(north|south)?(east|west)?)-\d$/;
const awsKmsKeyResourceBasicValidator = /^key\/[0-9A-Za-z-]+/;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const multipleSpacesPattern = /^\s+$/;
const phoneNumberRegex = /^\+[1-9][0-9]{2,18}$/;
const tenantUUIDPattern =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export const validateTenantUUID = (tenantUUID) => {
  return tenantUUIDPattern.test(tenantUUID);
};

export const validateMultipleSpaces = (value) => {
  return multipleSpacesPattern.test(value);
};

export const validateConnectInstanceARNPattern = (arn) => {
  return awsConnectInstanceARNPattern.test(arn);
};

export const validateConnectContactRecordKinesisStreamARNPattern = (arn) => {
  return awsConnectContactRecordKinesisStreamARNPattern.test(arn);
};

export const validateKinesisStreamName = (name) => {
  return kinesisStreamNamePattern.test(name);
};

export const validateS3BucketName = (name: string | undefined): boolean => {
  if (!name) return false;
  if (!s3BucketNamePattern.test(name)) return false;
  if (name.includes("..")) return false;
  if (/^\d+\.\d+\.\d+\.\d+$/.test(name)) return false;
  if (name.startsWith("xn--") || name.startsWith("sthree-")) return false;
  if (name.endsWith("-s3alias") || name.endsWith("--ol-s3")) return false;
  return true;
};

export const validateS3BucketNameOptional = (
  name: string | undefined,
): boolean => {
  if (name === undefined || name === null || name === "") return true; // Allow empty string
  return validateS3BucketName(name);
};

export const validateSecureURL = (secureUrl) => {
  try {
    let url = new URL(secureUrl);
    return url.protocol === "https:";
  } catch {
    return false;
  }
};

export const convertLocalTimeToUTCTime = (dateString) => {
  if (dateString === "") return "";
  let date = new Date(dateString);
  return date.toISOString();
};

export interface SubscriptionRole {
  tenantId: string;
  role: string;
}

export interface FilterTypeCase {
  state: string;
  subject: string;
}

export interface Arn {
  Partition: string;
  Service: string;
  AccountId: string;
  Region: string;
  Resource: string;
}

export interface InputError {
	invalid?: boolean;
	missing?: boolean;
  notSupported?: boolean,
  invalidName?: boolean,
};

export const currentTenant = (currentUser: any) => {
  const tenant = localStorage.getItem("clickedTenant") ? JSON.parse(localStorage.getItem("clickedTenant") ?? '') : "";
  return tenant ? currentUser?.tenants.find((x)=> x.tenantId === tenant.selectedTenant.id) : currentUser?.tenants[0];
};


export const getProductBySubscriptionId = (
  products: any,
  subscriptionId: string,
) => {
  let product = {};
  if (products && products.items) {
    products.items.forEach((prod) => {
      if (prod.subscriptions.find((x) => x.subscriptionId === subscriptionId)) {
        product = prod;
      }
    });
  }
  return product;
};

export function getSubscriptionRole(currentUser: any): SubscriptionRole {
  let subscriptionTypeRole: SubscriptionRole = {
    tenantId: "",
    role: "",
  };

  let isAdmin = currentTenant(currentUser).cognitoGroups.filter(
    (role: string) => role.indexOf("admin") > -1,
  );

  subscriptionTypeRole.tenantId = currentTenant(currentUser)?.tenantId ?? "";
  subscriptionTypeRole.role = isAdmin ? "admin" : "reader";
  return subscriptionTypeRole;
}

export const eventBus = {
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
};

export const badgeColor = (status) => {
  switch (status) {
    case "New":
      return "badge-gold";
    case "Open":
      return "badge-green";
    case "WaitingForSupport":
    case "WaitingForCustomer":
      return "badge-gold";
    case "Resolved":
      return "badge-purple";
    case "AwaitingInfo":
      return "badge-blue";
    case "Closed":
      return "badge-black";
    default:
      return "badge-red";
  }
};

export const badgeStateColor = (state) => {
  interface StateTextColor {
    text: string;
    color: "success" | "danger" | "warning";
  }
  let resultState: StateTextColor = {
    text: "",
    color: "success",
  };
  switch (state) {
    case "Operational":
      resultState.text = "Operational";
      resultState.color = "success";
      return resultState;
    case "WaitingOnDeploy":
      resultState.text = "Waiting For Deploy";
      resultState.color = "warning";
      return resultState;
    case "OperationalNotEditable":
      resultState.text = "Operational Not Editable";
      resultState.color = "warning";
      return resultState;
    default:
      resultState.text = "Not Operational";
      resultState.color = "danger";
      return resultState;
  }
};

export const getRoutesByFilter = (routes: any, featureFlags: any): any => {
  let filteredRoutes = routes.filter(
    (route) =>
      (featureFlags.userSettings !== "hide" &&
        route.name === "User Settings") ||
      (featureFlags.users !== "hide" && route.name === "Users") ||
      (featureFlags.configuration !== "hide" &&
        route.name === "Configuration") ||
      (featureFlags.support !== "hide" && route.name === "Support") ||
      (featureFlags.support !== "hide" && route.name === "Case") ||
      (featureFlags.configurationManager !== "hide" &&
        route.name === "Configuration Manager") ||
      (featureFlags.configurationManager !== "hide" && route.name === "S") ||
      (featureFlags.configurationManager !== "hide" && route.name === "Edit") ||
      (featureFlags.contactSuite !== "hide" &&
        route.name === "Contact Suite") ||
      (featureFlags.contactSuite !== "hide" && route.name === "S") ||
      (featureFlags.contactSuite !== "hide" && route.name === "Edit") ||
      (featureFlags.documentation !== "hide" && route.name === "Support") ||
      (featureFlags.documentation !== "hide" &&
        route.name === "Documentation") ||
      (featureFlags.documentation !== "hide" && route.name === "Articles") ||
      (featureFlags.documentation !== "hide" && route.name === "Article") ||
      (featureFlags.customerBusinessReporting !== "hide" &&
        route.name === "Customer Business Reporting") ||
      (featureFlags.security !== "hide" && route.name === "Security") ||
      (featureFlags.notification !== "hide" &&
        route.name === "Notifications") ||
      (featureFlags.tenantStatus !== "hide" &&
        route.name === "Health & Status") ||
      route.name === "" ||
      route.name === "Page404",
  );

  return filteredRoutes;
};

export const getStatusCircle = (itemStatus: any): any => {
  let color = "#5A9E50";
  switch (itemStatus) {
    case "online":
    case "success":
    case "HEALTHY":
      color = "#5A9E50";
      break;
    case "configuration":
    case "building":
    case "upgrading":
    case "pending":
    case "DEGRADED":
      color = "#ffc107";
      break;
    case "NOTICE":
      color = "#696073";
      break;
    case "unsubscribed":
    case "failed":
    case "UNHEALTHY":
      color = "#D32642";
      break;
    default:
      color = "#D32642";
      break;
  }
  return color;
};

export const getStatusText = (value: number): string => {
  switch (value) {
    case 300:
      return "Notice";

    case 409:
      return "Degraded";

    case 503:
      return "Unhealthy";
    case 200:
    default:
      return "Healthy";
  }
};

export const isValidEmailDomain = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const isValidURL = (url: string) => {
  const urlRegex = /^(https?):\/\/[^\s/$.?#].[^\s]*$/;
  return urlRegex.test(url);
};

export const isDeletedAnySessionCookie = () => {
  const awsSessionCookies = document.cookie
    .split("; ")
    .filter(
      (row) =>
        row.startsWith("CognitoIdentityServiceProvider") &&
        (row.indexOf(".idToken=") > -1 ||
          row.indexOf(".refreshToken=") > -1 ||
          row.indexOf(".accessToken=") > -1),
    );

  return awsSessionCookies && awsSessionCookies.length < 3;
};

export const getCookieByName = (name) => {
  let cookieValue = document.cookie
    .split("; ")
    .filter((row) => row.indexOf(`${name}=`) > -1);
  return cookieValue.length > 0 ? cookieValue[0].split("=")[1] : "";
};

export const deleteAllCookiesStartedByName = (name: string, path: string, domain: string, secure: boolean, partitioned: boolean) => {
  let cookiesToDelete = document.cookie
    .split("; ")
    .filter((row) => row.startsWith(`${name}`));
  cookiesToDelete.forEach((cookie) => {
    let cookieName = cookie.split("=")[0];
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; domain=${domain}; ${secure ? 'secure;':''} ${partitioned ? 'partitioned;': ''}`;
  });
};

export const getRemaingRefreshTime = () => {
  const jwtToken = parseJwt(getSessionCookieByEndName("idToken"));
  if (jwtToken && jwtToken.exp) {
    return convertExtToMiliSeconds(jwtToken.exp);
  } else {
    return 0;
  }
};

const getSessionCookieByEndName = (cookieEndName) => {
  const awsSessionCookies = document.cookie
    .split("; ")
    .filter(
      (row) =>
        row.startsWith("CognitoIdentityServiceProvider") &&
        row.indexOf(`.${cookieEndName}=`) > -1,
    );

  if (awsSessionCookies && awsSessionCookies.length > 0) {
    return awsSessionCookies[0].split("=")[1];
  } else {
    return "";
  }
};

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    //console.log('parseJwt error', e);
    return null;
  }
};

export const convertExtToMiliSeconds = (exp) => {
  let currentTimeSeconds = Math.round(+new Date() / 1000);
  let remainingMiliSeconds = (exp - currentTimeSeconds) * 1000;
  return remainingMiliSeconds;
};

export const splitCamelCase = (input: string): string => {
  const words = input.split(/(?=[A-Z][a-z])|(?<=[a-z])(?=[A-Z])/);
  const result = words
    .map((word) => {
      if (word === word.toUpperCase()) {
        return word;
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join(" ");
  return result.replace('Arn', 'ARN').replace('Kms', 'KMS').replace('Aws', 'AWS');
};

export const parseArn = (value: string): Arn | null => {
  const arnParts = value && value.toString().indexOf(':') > -1 ? value.split(":") : [''];
  if (arnParts.length === 6) {
    return {
      Partition: arnParts[1],
      Service: arnParts[2],
      AccountId: arnParts[4],
      Region: arnParts[3],
      Resource: arnParts[5],
    };
  }
  return null;
};

export const validateKmsKeyArn = (value: string): boolean => {
  if (value === null || value === undefined || value === "") return true;
  const arn: Arn | null = parseArn(value);
  return (
    arn !== null &&
    arn.Partition === "aws" &&
    arn.Service === "kms" &&
    awsAccountIdValidator.test(arn.AccountId) &&
    awsRegionValidator.test(arn.Region) &&
    awsKmsKeyResourceBasicValidator.test(arn.Resource)
  );
};

export const forceReloadOrRedirect = (target) => {
  if (target) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== "development") {
      window.location.href = `/${target}`;
    }
  } else {
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== "development") {
      window.location.href = "/";
    }
  }
};

export const validateEmail = (email) => {
  return emailRegex.test(email);
};

export const validateInternationalPhoneNumber = (phoneNumber) => {
  return phoneNumberRegex.test(phoneNumber);
};

export const toFormattedDateTime = (
  dateToFormat: string | Date,
  isTimeAgoFormat?: boolean,
): string => {
  try {
    const dateFromPortal = new Date(dateToFormat);
    if (isTimeAgoFormat) {
      return timeAgo.format(dateFromPortal);
    }
    var dateFormatter = new Intl.DateTimeFormat([], {
      localeMatcher: "best fit",
      dateStyle: "medium",
      timeStyle: "long",
    });
    return dateFormatter.format(dateFromPortal);
  } catch (err) {
    console.log(`Could not format ${dateToFormat} as local date`, err);
    return dateToFormat.toString();
  }
};

export const encodeUrl = (url: string) => encodeURIComponent(url);

export const decodeUrl = (url: string) => decodeURIComponent(url);

export const brandOptions = {
  0: "Other",
  1: "Microsoft Active Directory Federation Services (AD FS)",
  2: "Microsoft Azure Active Directory (Azure AD)",
};

export const isUtf8Valid = (value: string): boolean => {
  // eslint-disable-next-line no-control-regex
  return /^[\x00-\xFF]*$/.test(value);
};

export const downloadFile = (
  contentFile: string,
  fileName: string,
  contentType: string,
) => {
  let a = document.createElement("a");
  a.download = fileName;
  a.href = URL.createObjectURL(
    new File([contentFile], fileName, { type: contentType }),
  );
  a.click();
};

export const validateCookieSize = (localStorageItemValue: string | null) => {
  const encoder = new TextEncoder();
  const bytes = encoder.encode(localStorageItemValue ?? "");
  return bytes.length < 4096;
};

export const yesNoValues = [
  {
    value: "NO",
    label: "NO",
  },
  {
    value: "YES",
    label: "YES",
  },
];

export const getCurrentYesNoValue = () => {
  return yesNoValues.find((value) => {
    return value.value === "NO";
  });
};

export enum EncryptionBucketSSR {
	SSE_S3 = "SSE-S3",
	SSE_KM = "AWS KMS",
	//OTHERS = "Other"
}